import Api from "../config/api";

export const inativarProfissionalSaudeExterno = async (profissionalSaudeId) => {
  const response = await Api.post("", {
    query: `
      mutation($profissionalSaudeId: Long) {
        inativarProfissionalSaudeExterno(profissionalSaudeId: $profissionalSaudeId) {
          id
        }
      }
    `,
    variables: {
      profissionalSaudeId,
    },
  });
  if (response.data.errors) {
    throw response.data.errors;
  }

  return response.data.data.inativarProfissionalSaudeExterno;
};

export const ativarProfissionalSaudeExterno = async (profissionalSaudeId) => {
  const response = await Api.post("", {
    query: `
      mutation($profissionalSaudeId: Long) {
        ativarProfissionalSaudeExterno(profissionalSaudeId: $profissionalSaudeId) {
          id
        }
      }
    `,
    variables: {
      profissionalSaudeId,
    },
  });
  if (response.data.errors) {
    throw response.data.errors;
  }

  return response.data.data.ativarProfissionalSaudeExterno;
};

export const findAllProfissionalExternoAtivo = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($pageableDTO: PageableDTOInput, $search: String, $ativo: Boolean) {
      findAllProfissionalExternoAtivo(pageableDTO: $pageableDTO, search: $search, ativo: $ativo) {
        last
        content {
          id
          nome
          numeroConselho
          ufConselho
          ativo
        }
      }
    }`,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }

  return response.data.data.findAllProfissionalExternoAtivo;
};

export const findAllEstadoList = async () => {
  const response = await Api.post("", {
    query: `
      {
        findAllEstadoList {
          id
          uf
        }
      }
    `,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }

  return response.data.data.findAllEstadoList;
};

export const saveProfissionalExterno = async (variables) => {
  const action = variables.profissionalSaude?.id
    ? "updateProfissionalSaudeExterno"
    : "createProfissionalSaudeExterno";

  const response = await Api.post("", {
    query: `
      mutation($profissionalSaude: ProfissionalSaudeInput) {
        ${action}(profissionalSaude: $profissionalSaude) {
          id
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }

  return response.data.data[action];
};

export const findAllConselhoProfissionalSaudeList = async () => {
  const response = await Api.post("", {
    query: `
      {
        findAllConselhoProfissionalSaudeList {
          id
          descricao
          codigoTermo
          sigla
      }
    }
    `,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.findAllConselhoProfissionalSaudeList;
};

export const findAllTuss24TerminologiaCBO = async (pageableDTO, searchDTO) => {
  const response = await Api.post("", {
    query: `
      query($termo: String, $codigoTermo: String, $pageableDTO: PageableDTOInput ){
        findAllTuss24TerminologiaCBO (termo:$termo, codigoTermo: $codigoTermo, pageableDTO: $pageableDTO){
          last
          content{
            id
            codigoTermo
            termo
          }
        }
      }`,
    variables: { pageableDTO, ...searchDTO },
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.findAllTuss24TerminologiaCBO;
};

export const findByIdProfissionalSaudeExterno = async (profissionalSaudeId) => {
  const response = await Api.post("", {
    query: `
     query ($profissionalSaudeId: Long) {
      findByIdProfissionalSaudeExterno(profissionalSaudeId: $profissionalSaudeId) {
        id
        nome
        numeroConselho
        ufConselho
        ativo
        conselhoProfissionalSaude {
          id
          descricao:sigla
        }
        tuss24TerminologiaCBO {
          id
          codigoTermo
          termo
        }
      }
    }`,
    variables: { profissionalSaudeId },
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.findByIdProfissionalSaudeExterno;
};

export const findAllProfissionalSaudeAuditByProfissionalSaudeId = async ({
  profissionalSaudeId,
  pageableDTO,
}) => {
  const response = await Api.post("", {
    query: `
    query ($profissionalSaudeId: Long, $pageableDTO: PageableDTOInput) {
      findAllProfissionalSaudeAuditByProfissionalSaudeId(profissionalSaudeId: $profissionalSaudeId, pageableDTO: $pageableDTO) {
        last
        content {
          revisao {
            dataHoraModificacao
            usuario {
              fotoPerfil
              nome
            }
          }
          revtype
          nomeModified
          ativoModified
          numeroConselhoModified
          ufConselhoModified
        }
      }
    }`,
    variables: {
      profissionalSaudeId,
      pageableDTO,
    },
  });

  if (response.data?.data?.findAllProfissionalSaudeAuditByProfissionalSaudeId) {
    return response.data.data
      .findAllProfissionalSaudeAuditByProfissionalSaudeId;
  } else {
    throw new Error(response.data.errors[0]);
  }
};
