import React from "react";

const ProfissionaisExternosImpressao = ({ dadosRelatorio, filters }) => {
  const columnColor = (index) => {
    if (index % 2 !== 0) {
      return { backgroundColor: "#F2F2F2" };
    }
    return "";
  };

  const { search, ativo } = filters || {};
  const naoPosuiFiltroStatus = ativo === null;

  return (
    <>
      <div style={styles.titulo}>Relatório de Profissionais Externos</div>
      <div style={styles.subTitulo}>
        {search && (
          <div>
            Pesquisa:
            <span>{search}</span>
          </div>
        )}
        {!naoPosuiFiltroStatus && (
          <div>
            Status:
            <span>{ativo ? "Ativo" : "Inativo"}</span>
          </div>
        )}
      </div>
      <div style={styles.headerColumn}>
        <div style={styles.column}>Nome do profissional externo</div>
        <div style={styles.column}>Número do conselho</div>
        <div style={styles.column}>UF do conselho</div>
        <div style={styles.column}>Status</div>
      </div>
      <div>
        {dadosRelatorio.map((item, index) => {
          return (
            <div
              key={item.id}
              style={{ ...styles.containerColuna, ...columnColor(index) }}
            >
              <div style={styles.column}>{item.nome}</div>
              <div style={styles.column}>{item.numeroConselho}</div>
              <div style={styles.column}>{item.ufConselho}</div>
              <div style={styles.column}>
                {item.ativo ? "Ativo" : "Inativo"}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const styles = {
  titulo: {
    marginLeft: "0px",
    marginBottom: "10px",
    marginTop: "5px",
    paddingBottom: "5px",
    textAlign: "center",
    fontWeight: 700,
  },
  subTitulo: {
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #505050",
    marginBottom: "15px",
    paddingBottom: "5px",
    fontWeight: 700,
    justifyContent: "center",
    gap: "8px",
  },

  headerColumn: {
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
    width: "100%",
  },
  containerColuna: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  column: {
    width: "100%",
  },
};

export default ProfissionaisExternosImpressao;
