import colors from "../../../template/Colors";

const styles = {
  content: {
    overflow: "auto",
    height: "100%",

    "& .statusPagamento": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      color: "#505050",
      padding: "4px 8px",
      fontSize: "13px",
      height: 22,
      gap: "5px",
    },
    "& .Pago": {
      backgroundColor: colors.commons.greenLight,
      border: `0.5px solid ${colors.commons.green}`,
    },
    "& .Atrasado": {
      backgroundColor: colors.commons.redLight,
      border: `0.5px solid ${colors.commons.red}`,
    },
    "& .Aberto": {
      backgroundColor: colors.commons.yellowLight,
      border: `0.5px solid ${colors.commons.yellow}`,
    },
    "& .vencimento": {
      whiteSpace: "nowrap",
    },
  },
  fullWidth: {
    width: "100%",
  },
  iconStatusPagamento: {
    height: 12,
    width: 12,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  inputDate: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    width: "100%",

    "& > div": {
      border: "none",
    },
    "& input": {
      textAlign: "center",
      fontFamily: "Poppins",
    },
  },
  inputValor: {
    width: "calc(100% - 10px)",
    background: "#F2F2F2",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  selectParcelas: {
    width: "100%",
    "&>div>div": {
      height: 32,
      borderRadius: "8px",
      display: "flex",
      alignContent: "center",
      background: "#f2f2f2",
    },
  },
  inputPercentil: {
    height: 32,
    borderRadius: "8px",
    display: "flex",
    alignContent: "center",
    background: "#f2f2f2",
  },
  inputRootPercentil: {
    border: "1px solid rgba(220, 220, 220, 0.2)",
    height: "30px",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  inputSearch: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  notchedOutlineSearch: {
    border: "none",
  },
  vencimento: {
    whiteSpace: "nowrap",
  },
  descricao: {
    "& textarea": {
      height: "100%",
      borderRadius: "8px",
    },
    "&> div": {
      padding: 0,
    },
  },
  inputValorDesconto: {
    display: "flex",
    alignItems: "end",
  },
  itemGridDescricao: {
    "&> div": {
      width: "100%",
    },
  },
  fieldDisabled: {
    display: "flex",
    alignItems: "center",
  },
  divDisabled: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    borderRadius: '8px',
    background: '#F2F2F2',
  },
  contentButtonBack: {
    display: "flex",
    cursor: "pointer",
    color: "#505050",
    margin: "4px 0",
  },
  contentButtonCriar: {
    alignSelf: 'flex-end'
  },
  containerVoucher: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    color: "white",
    padding: "16px",
    gap: "5px",
    margin: "8px",
    background: "#00908B",
  },
  notFoundContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "8px",
  },
  tableContainer: {
    width: '100%',
    background: '#F9F9F9',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    borderCollapse: 'collapse',
    '& thead': {
      background: '#F2F2F2',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      fontSize: '12px !important',
    },

    '& th': {
      textAlign: 'left',
      padding: '8px',
    },
    '& td': {
      padding: '8px',
      textAlign: 'left',
      color: '#505050',
      fontWeight: '700',
      fontSize: '12px',
      lineHeight: '18px',
    },
    '& tbody>tr': {
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    },
    '& tbody>tr:last-child': {
      borderBottom: 'none',
    },
  },
  wrapperTable: {
    overflow: 'auto',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    marginTop: '8px',
  },
  contentInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
    padding: '4px 8px',
    margin: '8px 0',
    borderRadius: '28px',
    border: '1px solid rgba(220, 220, 220, 0.2)',
    background: '#F2F2F2',
    color: '#219A97',
    width: 'fit-content',
    '& a': {
      textDecoration: 'underline',
      color: '#219A97',
      fontWeight: '700',
      cursor: 'pointer',
    },
  },
  textInfo: {
    color: '#219A97',
    fontWeight: '700',
    lineHeight: '21px',
  },
};

export default styles;
