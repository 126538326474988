import React from "react";
import { Switch } from "@material-ui/core";

export const pageableDTODefault = {
  pageNumber: 0,
  pageSize: 30,
  sortDir: "ASC",
  sortField: "nome",
};

export const columns = ({ onClickSwitch }) => [
  {
    Header: "Nome",
    getValue: (profissionalExterno) => profissionalExterno.nome,
    props: { component: "th", scope: "row" },
    field: "nome",
  },
  {
    Header: "Número conselho",
    getValue: (profissionalExterno) => profissionalExterno.numeroConselho,
    props: { component: "th", scope: "row" },
  },
  {
    Header: "UF conselho",
    getValue: (profissionalExterno) => profissionalExterno.ufConselho,
    props: { component: "th", scope: "row" },
  },
  {
    Header: "Status",
    getValue: (profissionalExterno) => (
      <Switch
        onClick={() => onClickSwitch(profissionalExterno)}
        checked={profissionalExterno.ativo}
        color="primary"
      />
    ),
    isClick: true,
    props: { center: "true  " },
  },
];

export const optionsStatus = [
  {
    value: true,
    label: "Ativo",
  },
  {
    value: false,
    label: "Inativo",
  },
];

export const profissionalExternoDefault = {
  id: null,
  nome: "",
  numeroConselho: "",
  ativo: true,
  ufConselho: "",
  tuss24TerminologiaCBO: null,
  conselhoProfissionalSaude: null,
};
