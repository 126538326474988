import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import styles from '../../../assets/jss/pages/RecebimentosViziStyle';
import PageTitle from '../../../components/PageTitle/PageTitle';
import classNames from 'classnames';
import { tabs } from '../constants/recebimentosViziConstants';
import TabSelector from '../../../components/TabSelector';
import DadosBancarios from './DadosBancarios';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import Notification from '../../../components/Notification';
import ExtratoPagamentos from './ExtratoPagamentos';
import InfoIconFilled from '../../../components/Icon/InfoIconFilled';
import { getWhitelabelNome } from '../../../services/WhitelabelService';

const RecebimentosVizi = observer(({ classes, profissionalSaudeBraspagStore, location, unidadeBraspagStore }) => {
  const recebimento = {
    profissional: {
      load: () => loadProfissionalSaudeLogadoDadosBraspag(),
      store: profissionalSaudeBraspagStore,
      dados: "profissionalSaudeDadosBraspag",
    },
    unidade: {
      load: () => loadUnidadeLogadoDadosBraspag(),
      store: unidadeBraspagStore,
      dados: "unidadeDadosBraspag",
    }
  }
  const telaRecebimento = location.pathname.includes('profissional') ?  'profissional' : 'unidade';
  const currentRecebimento = recebimento[telaRecebimento]
  const currentRecebimentoStore = currentRecebimento.store

  useEffect(() => {
    currentRecebimento.load();
  }, []);
  
  const loadProfissionalSaudeLogadoDadosBraspag = async () => {
    await profissionalSaudeBraspagStore.findByProfissionalSaudeBraspag();
  };

  const loadAnexosDadosBraspag = async () => {
    await profissionalSaudeBraspagStore.findAnexosByProfissionalSaudeBraspag();
  }

  const loadUnidadeLogadoDadosBraspag = async() => {
    await unidadeBraspagStore.findByUnidadeBraspag();
  }
  
  const handleSelectTab = (tabIndex) => {
    const { selectedTab } = currentRecebimentoStore;
    if (tabIndex !== selectedTab) {
      currentRecebimentoStore.selectedTab = tabIndex;
    }
  }

  const showAlertMessage = (message, variant) => {
    currentRecebimentoStore.notification.open = true;
    currentRecebimentoStore.notification.variant = variant;
    currentRecebimentoStore.notification.message = message;
  }

  return (
    <div className={classes.tableContainer}>
      <div className={classes.header}>
        <PageTitle title={`Recebimentos ${telaRecebimento}`} />
        <div className={classes.headerText}>
          <h3 className={classNames(classes.titleHeader, classes.headerTitleRoot)}>Recebimentos {telaRecebimento}</h3>
          <span>Listagem de todos os dados para recebimento dos agendamentos pagos pela plataforma {getWhitelabelNome()}</span>
        </div>
      </div>
      {currentRecebimentoStore[currentRecebimento.dados].status !== 'APPROVED' && (
        <div className={classes.contentInfo}>
          <InfoIconFilled color='#505050' />
          <span>
            Preencha seus dados bancários para ativar o recebimento online por link de pagamento
          </span>
        </div>
      )}
      <div className={classes.content}>
        <div style={{ padding: '16px' }}>
          <TabSelector 
            selectedColor="#FFF"
            baseColor="#F2F2F2"
            onSelect={handleSelectTab}
            selectedTabIndex={currentRecebimentoStore.selectedTab}
            tabsOptions={tabs}
          />
        </div>
        {currentRecebimentoStore.selectedTab === 0 && (
          <ExtratoPagamentos 
            isProfissional={telaRecebimento === 'profissional'}
            showAlertMessage={showAlertMessage}
          />
        )}
        {currentRecebimentoStore.selectedTab === 1 && 
          <DadosBancarios 
            braspagStore={currentRecebimentoStore}
            dadosBraspag={currentRecebimento.dados}
            showAlertMessage={showAlertMessage}
            loadAnexosDadosBraspag={loadAnexosDadosBraspag}
          />
        }
      </div>
      <Notification
        isOpen={currentRecebimentoStore.notification.open}
        message={currentRecebimentoStore.notification.message}
        variant={currentRecebimentoStore.notification.variant}
        close={currentRecebimentoStore.closeNotification}
      />
    </div>
  )
});

const RecebimentosViziStyle = withStyles(styles)(RecebimentosVizi);
export default inject("profissionalSaudeBraspagStore", "unidadeBraspagStore")(RecebimentosViziStyle);
