import { withStyles } from "@material-ui/core/es";
import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import ItemGrid from "../../../components/Modal/ItemGrid";
import styles from "./TabPagamentoStyles";
import StatusPagamento from "../../../components/Financeiro/StatusPagamento";
import InformacoesTitulo from "./InformacoesTitulo";
import ContentListaTitulos from "./ContentListaTitulos";
import ArrowLeftIcon from "../../../components/Icon/ArrowLeftIcon";
import { defaultTitulo } from "../../../stores/Financeiro/Extrato.store";
import { Button } from "../../../components/ui/Buttons";
import ContentListTitulosTabPagamento from "./ContentListTitulosTabPagamento";
import MoneyIcon from "../../../components/Icon/Money";
import { CircularProgress } from "@material-ui/core";
import InfoIconFilled from "../../../components/Icon/InfoIconFilled";

const AtendimentoModalTabPagamento = observer((props) => {
  const { classes, atendimentoStore, extratoStore, profissionalSaudeBraspagStore, history } = props;
  const { objView, showTitulo, isModalSala } = atendimentoStore;
  const { convenio, titulo: titulosAtendimento } = objView || {};
  const { titulo, isTituloVinculaAgendamento, vouchersAssociado, loadingVouchers } = extratoStore;
  const { tituloParcelaList } = extratoStore.modalState;


  const isPossuiTitulo = titulosAtendimento?.some(item => item.ativo === true);
  const isPossuiTituloFinanceiro = titulo?.id;

  const ultimaParcela = tituloParcelaList[tituloParcelaList.length - 1];
  const showTituloVinculado = titulosAtendimento?.some(item => item.ativo === true) || showTitulo || isTituloVinculaAgendamento; 

  useEffect(() => {
    loadProfissionalSaudeLogadoDadosBraspag();
    extratoStore.isAgendamento = true;
    objView?.titulo && objView?.titulo[0]?.id ? getDadosTitulo() : atendimentoStore.updateTotal(isModalSala);
    extratoStore.onlyReadParcelas = true;
    extratoStore.editMode = true;
    extratoStore.findVouchersAssociado({
      sujeitoAtencaoId: objView?.sujeitoAtencao?.id,
      status: "EMITIDO",
    });
  }, []);

  const getDadosTitulo = async () => {
    await extratoStore.preencherDadosPagamentoDoAgendamento()
  };

  const handleBack = () => {
    atendimentoStore.showTitulo = false;
    extratoStore.isTituloVinculaAgendamento = false;
    extratoStore.titulo = defaultTitulo;
  }
  const loadProfissionalSaudeLogadoDadosBraspag = async () => {
    await profissionalSaudeBraspagStore.findByProfissionalSaudeBraspag();
  };

  const showMessageLinkPagamento = profissionalSaudeBraspagStore.profissionalSaudeDadosBraspag.status !== 'APPROVED';

  const showVouchers = vouchersAssociado?.length > 0 && !objView?.voucherPrever?.id && !loadingVouchers && !isPossuiTituloFinanceiro;

  return (
    <>
      {
        titulosAtendimento && titulosAtendimento.length > 1 ?
        <ContentListTitulosTabPagamento history={history} />
        :
        <div className={classes.content}>
        {
          (!isPossuiTituloFinanceiro && showTituloVinculado) && <div className={classes.contentButtonBack} onClick={handleBack}>
            <ArrowLeftIcon />
            <span> Voltar </span>
          </div>
        }
        {loadingVouchers && (
          <div className={classes.notFoundContainer}>
            <CircularProgress size={16} />
          </div>
        )}
        {showVouchers && (
          <div className={classes.containerVoucher}>
            <MoneyIcon />
            {vouchersAssociado?.length} VOUCHER DISPONÍVEL
          </div>
        )}
          {!isPossuiTituloFinanceiro && !showTituloVinculado && showMessageLinkPagamento &&
          <div className={classes.contentInfo}>
            <InfoIconFilled color='#219A97' />
            <span>Receba pelo link de pagamento online <a onClick={() => history.push("/perfil/recebimentos-profissional")}>configure seus dados</a> </span>
          </div>
          }
        <div className={classes.row}>
          <ItemGrid size={8} label="Convênio">
            <div className={classes.divDisabled}> {convenio?.descricao || ""} </div>
          </ItemGrid>
          <ItemGrid size={4} classes={{ item: classes.contentButtonCriar }}>
            {(showTituloVinculado || (!isPossuiTitulo && titulo?.id)) ?
              <StatusPagamento
                dataVencimento={titulo.tipoRepeticao === "NAO_REPETIR" ? titulo?.dataVencimento : ultimaParcela?.dataVencimento}
                dataPagamento={titulo.tipoRepeticao === "NAO_REPETIR" ? titulo?.dataPagamento : ultimaParcela?.dataPagamento}
                status={!isPossuiTitulo && "Aberto"}
              />
              :
              <Button
                onClick={() => atendimentoStore.showTitulo = true}
                size='mini'
                fullWidth
                style={{ height: '32px' }}
              >
                Criar pagamento
              </Button>}
          </ItemGrid>
        </div>
        {(isPossuiTitulo || showTituloVinculado || (!isPossuiTitulo && titulo?.id)) ?
          <InformacoesTitulo />
          :
          <ContentListaTitulos />
        }
      </div>}
    </>
  );
});

const AtendimentoModalTabPagamentoWithStyles = withStyles(styles)(
  AtendimentoModalTabPagamento
);
export default inject(
  "atendimentoStore",
  "extratoStore",
  "profissionalSaudeBraspagStore"
)(AtendimentoModalTabPagamentoWithStyles);
