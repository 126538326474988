import colors from "../../../template/Colors";

const styles = theme => ({
    content: {
        gridArea: "content",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        position: "relative",
        overflow: "hidden",

        "& thead th": {
            textAlign: "left",
            top: 0,
            position: "sticky",

            "&:first-child": {
                padding: "0 0 0 24px",
            }

        },
        "& tbody td": {
            padding: "0 0 0 24px",
            maxWidth: "120px",
            width: "100%",

            "& .statusPagamento": {
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                borderRadius: 10,
                color: "#868686",
                padding: "4px 8px",
                fontSize: "13px",
            },
            "& .Pago": {
                backgroundColor: colors.commons.greenLight,
                border: `1px solid ${colors.commons.green}`,
            },
            "& .Atrasado": {
                backgroundColor: "#FCF0DF",
                border: "1px solid #DB7C2F",
            },
            "& .Aberto": {
                backgroundColor: colors.commons.blueLight,
                border: `1px solid ${colors.commons.blue}`,
            },
            "& .Excluído": {
                backgroundColor: "#FCEDEF",
                border: "1px solid #BF3028",
            }
        }
    },

    contentSearch: {
        display: "flex",
        flexDirection: "column",
        gap: "4px"
    },

    titulo: {
        color: "#000",
        fontWeight: 700,
        fontSize: "18px",
    },

    search: {
        gridArea: "search",
        padding: "4px 16px 8px 16px",
        boxShadow: "10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)",
        background: "#fff",
        zIndex: 1
    },

    lista: {
        gridArea: "lista",
        marginBottom: 20,
        overflow: "hidden"
    },

    headerTitleRoot: {
        maxWidth: theme.commons.menuLeft.width
    },

    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        flexGrow: 1
    },

    titleHeader: {
        fontSize: 25,
        fontWeight: "bold",
        color: theme.palette.primary.main,
        paddingLeft: 15,
    },

    tableContainer: {
        paddingTop: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
    },

    tableWrapper: {
        display: "flex",
        overflowY: "scroll",
        overflowX: "visible",
        height: "100%"
    },

    scrollContainer: {
        height: "70vh",

        "@media(max-height: 760px)": {
            height: "65vh",
        }
    },

    notFoundContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80%",

        "& h3": {
            color: colors.commons.gray10,
            fontWeight: "normal",
            fontSize: "1rem"
        }
    },

    buttonAddDespesa: {
        backgroundColor: "#FB7676 !important",
        color: "#fff"
    },

    buttonAddReceita: {
        backgroundColor: "#00C1BF !important",
        color: "#fff"
    },

    selectSearch: {
        width: "100%",
        color: `${colors.commons.gray9} !important`,
        "& > div": {
            "& > div": {
                maxHeight: 32,
                "& > div": {
                    maxHeight: 30,
                }
            }
        }
    },

    selectSearchAsync: {
        width: "100%",
    },

    buttonImprimir: {
        background: colors.commons.yellow,
        width: "40px",
        height: "40px",
        "&:hover": {
            background: colors.commons.darkYellow,
        },
    },
    floatButtonsContainer: {
        position: "absolute",
        bottom: 24,
        right: 37,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "end",
        columnGap: "8px",
    },
    spacing: {
        paddingRight: 5,
    },
    tituloFiltros: {
        color: colors.commons.gray7,
        fontSize: "12px !important",
        height: "27px",
        alignItems: "end",
        display: "flex"
    },
    inputMes: {
        color: colors.commons.fontColor,
        paddingTop: 0,
    },
    totalReceitaLabel: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: colors.commons.gray9,
        fontFamily: "Poppins",
        height: "18px",
        marginBottom: "9px",
        fontSize: 12,
        "& span": {
            color: "#00C1BF",
            fontSize: 14,
        }
    },
    totalDespesaLabel: {
        fontFamily: "Poppins",
        color: colors.commons.gray9,
        fontSize: 12,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "18px",
        marginBottom: "9px",
        "& span": {
          color: "#FB7676",
          fontSize: 14,
        }
    },
    totalLabel: {
        fontFamily: "Poppins",
        color: colors.commons.gray9,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderTop: "1px solid #F5F5F5",
        paddingTop: 3,
        fontSize: 14,
        height: "21px",
        marginTop: "6px",
        "& span": {
            fontWeight: 600
        }
    },
    header: {
        display: "flex",
        flexDirection: "row",
        background: "#fff",
        justifyContent: "space-between",
        padding: "8px 16px",
        gap: "16px",
    },
    containerHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "end",
        gap: "16px",
    },
    selectMulti: {
        width: "100%",
        maxHeight: 32,
        "&> div": {
            "&> div": {
                maxHeight: 32,
                alignContent: "center",
            }
        }
    },
    formaPagamentoSelecionado: {
        display: "flex",
        color: colors.commons.gray9,
        background: "#F8F8F8",
        borderRadius: "100px",
        padding: "0 10px",
        marginRight: "10px",
        alignItems: "center",
        height: "26px",
        border: "1px solid rgba(0, 0, 0, 0.05)",

    },
    divCloseIcon: {
        display: "flex",
        cursor: "pointer",
        marginLeft: "5px",
        borderRadius: "100px",
        "& svg": {
            fontSize: 15
        },
        "&:hover": {
            background: "#F2F2F2"
        }
    },
    gridFormaPagamentoSelecionado: {
        display: "flex",
        marginTop: "16px",
    },
    tableDadosAniversariantes: {
        height: "100%",
    },
    spacingHeader: {
        padding: "0 8px",
        maxWidth: 152,
    },
    inputSearch: {
        width: 250,
        height: 30,
    },
    paperInputSearch: {
        width: "87%",
    },
    filterDateContainer: {
        width: "250px",
        gap: "4px",
        display: "flex",
        flexDirection: "column"
    },
    wrapperInfosLancamentos: {
        display: "flex",
        alignItems: "center",
        gap: "16px",
    },
    infosLancamentos: {
        display: "flex",
        flexDirection: "column",
        fontSize: "12px !important",
        color: colors.commons.gray7,

        "& strong": {
            fontSize: "16px",
            fontWeight: "600",
        }
    },
    wrapperFilterMonth: {
        display: "flex",
        alignItems: "center",
        gap: "8px",

        "& button": {
            borderRadius: "100px",
            fontSize: "14px",
            color: "#505050",
            fontWeight: "600",
            width: "fit-content",
            height: "fit-content",
            minWidth: "fit-content",
            textWrap: "nowrap",
            border: "1px solid transparent !important",
            "&:hover": {
                border: "1px solid #219A97 !important",
                backgroundColor: "#F1FCFA",
            }
        }
    },
    wrapperProfile: {
        display: "flex",
        alignItems: "end",
    },
    tooltip: {
        backgroundColor: "#5F6368",
        border: "1px solid rgba(220, 220, 220, 0.20)",
        color: "#fff",
        fontSize: "10px",
        fontWeight: "400",
    },
	inputContainer:{
		width: "calc( 100% - 20px )",
		background: "#F2F2F2",
		color: "#505050",
		borderRadius: "8px",
		height: "32px",
		padding: "4px 8px",

		"& > div": {
			"& > p": {
				overflow: "hidden",
				textOverflow: "ellipsis",
				whiteSpace: "nowrap",
			}
		}
	},
    wrapperInfosModal: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",

        "& h5": {
            fontSize: "18px",
            fontWeight: "600",
            color: "#505050",
            margin: 0,
        }
    },

    wrapperButtonsModal: {
        display: "flex",
        flexDirection: "row",
        gap: "16px",
    },

    totalTicketMedio:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height:"18px",
        color: "#505050",
        fontFamily: "Poppins",
        fontSize: "14px",
        "&>span":{
            color:"#505050",
            fontWeight: "600",
        }
    },
    infoButton:{
        height:"121px !important",
    },
    contentTotalDespesasEReceitas:{
        height:"86px !important",
    }
});

export default styles;
