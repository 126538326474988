import { inject } from "mobx-react";
import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CircularProgress } from "@material-ui/core";
import styles from "./styles";
import AVista from "./TabParcelamento/AVista";
import Repetir from "./TabParcelamento/Repetir";
import Parcelar from "./TabParcelamento/Parcelar";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { tiposRepeticao, TituloTipoRepeticao } from "../../../stores/Financeiro/Extrato.store";
import InfoIconFilled from "../../../components/Icon/InfoIconFilled";

const Parcelamento = observer((props) => {
  const { classes, extratoStore, handleChange, handleClose, atendimentoStore, history, profissionalSaudeBraspagStore } = props;
  const { titulo, tabSelectedParcelamento, modalState } = extratoStore;
  const { objView } = atendimentoStore;
  const { titulo:tituloAtendimento } = objView || {};

  useEffect(() => {
    criarParcelas();
    loadProfissionalSaudeLogadoDadosBraspag();
  }, []);

  const criarParcelas = async() => {
    (titulo?.id || tituloAtendimento?.id) && await extratoStore.findByIdTituloIdParcelas(titulo.id || tituloAtendimento?.id);
  };

  useEffect(() => {
    if(extratoStore.editMode){
      modalState.tipoRepeticao === TituloTipoRepeticao.PARCELAR && extratoStore.recalculaParcelas();
      modalState.tipoRepeticao === TituloTipoRepeticao.REPETIR && extratoStore.recalculaRepetir();
    }

  }, [
    titulo.subtotal,
    titulo.totalParcelas,
    titulo.valorEntrada,
    titulo.quantidade,
    titulo.dataVencimento,
    titulo.periodicidade])

  const posicaoDesabilitada = tiposRepeticao.map((item, index) => {
    const verificaTipo = item !== titulo.tipoRepeticao;
    return verificaTipo && index;
  });

  const renderTabParcelamento = () => {
    switch (tabSelectedParcelamento) {
      case 0:
      default:
        return <AVista />;
      case 1:
        return (
          <Parcelar
            titulo={titulo}
            handleChange={handleChange}
            modalState={extratoStore.modalState}
          />
        );
      case 2:
        return (
          <Repetir
            titulo={titulo}
            handleChange={handleChange}
            modalState={extratoStore.modalState}
            extratoStore={extratoStore}
          />
        );
    }
  };

  const loadProfissionalSaudeLogadoDadosBraspag = async () => {
    await profissionalSaudeBraspagStore.findByProfissionalSaudeBraspag();
  };

  const showMessageLinkPagamento = profissionalSaudeBraspagStore.profissionalSaudeDadosBraspag.status !== 'APPROVED';

  return (
    <div className={classes.parcelamento}>
      <div className={classes.titleParcelamento}>
        Pagamento
        <div className={classes.buttonClose} onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </div>
      </div>
      {extratoStore.opening ? (
        <div className={classes.notFoundContainerParcela}>
          <CircularProgress className={classes.circularProgressStyle} />
        </div>
      ) : (
        <div className={classes.wrapperParcelamentoTab}>
          {showMessageLinkPagamento && (
            <div className={classes.contentInfo}>
              <InfoIconFilled color="#219A97" size={40}/>
              <span>Receba pelo link de pagamento online <a onClick={() => history.push('/perfil/recebimentos-profissional')}>configure seus dados</a></span>
            </div>
          )}
          <div className={classes.contentParcelamento}>
            <MultiToggleButtons
              options={["À vista", "À prazo", "Repetir"]}
              tabSelected={extratoStore.tabSelectedParcelamento}
              changeTabSelected={extratoStore.changeTabSelectedParcelamento}
              classes={{ buttonsContainer: classes.toggleButtons }}
              disabled={titulo?.id}
              posicaoDesabilitada={posicaoDesabilitada}
            />
            <div className={classes.contentTabParcelamento}>
              {renderTabParcelamento()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});

const ParcelamentoWithStyle = withStyles(styles)(Parcelamento);
export default inject("extratoStore", "atendimentoStore", "profissionalSaudeBraspagStore")(ParcelamentoWithStyle);
