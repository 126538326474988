import React, { useEffect, useReducer, useState } from "react";
import PageTitle from "../../../components/PageTitle/PageTitle";
import HeaderConfiguracoesButtonNovo from "../Components/HeaderConfiguracoesButtonNovo";
import {
  columns,
  optionsStatus,
  pageableDTODefault,
  profissionalExternoDefault,
} from "./constants";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import Table from "../../../components/Table/Table";
import {
  ativarProfissionalSaudeExterno,
  findAllProfissionalExternoAtivo,
  findByIdProfissionalSaudeExterno,
  inativarProfissionalSaudeExterno,
} from "../../../services/ProfissionaisExternosService";
import Notification from "../../../components/Notification";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import ModalProfissionalExterno from "./ModalProfissionalExterno";
import { withStyles } from "@material-ui/core/es";
import styles from "./styles";
import { Button } from "../../../components/ui/Buttons";
import PrintIcon from "../../../components/Icon/Print";
import { Add } from "@material-ui/icons";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import ProfissionaisExternosImpressao from "../../../template/pdf/configuracoes/ProfissionaisExternos";

const notificationDefault = { 
  open: false, 
  message: "", 
  type: "" 
};

const ProfissionaisExternos = ({ classes }) => {
  const [search, setSearch] = useState("");
  const [profissionaisExternos, setProfissionaisExternos] = useState([]);
  const [last, setLast] = useState(false);
  const [pageableDTO, setPageableDTO] = useState(pageableDTODefault);
  const [status, setStatus] = useState(optionsStatus[0]);
  const [loading, setLoading] = useState(false);
  const [isPrintMustache, setIsPrintMustache] = useState(false);
  const [
    profissionaisExternosImpressao,
    setProfissionaisExternosImpressao,
  ] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [
    profissionalExternoSelecionado,
    setProfissionalExternoSelecionado,
  ] = useState(profissionalExternoDefault);
  const [notification, setNotification] = useState(notificationDefault);

  const [open, toggleModalProfissionalExterno] = useReducer(
    (state) => !state,
    false
  );

  useEffect(() => {
    loadProfissionaisExterno({ isClearable: true });
  }, []);

  const handleClickOpenModalProfissionalExterno = async (profissional) => {
    try {
      const profissionalExterno = profissional?.id
        ? await findByIdProfissionalSaudeExterno(profissional.id)
        : profissionalExternoDefault;
      setProfissionalExternoSelecionado(profissionalExterno);
      toggleModalProfissionalExterno();
    } catch {
      openNotification({
        message: "Erro ao buscar o profissional externo",
        type: "error",
      });
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const onKeypressSearch = (event) => {
    if (event.key === "Enter" && search.length >= 3) {
      loadProfissionaisExterno({ isClearable: true });
    }
  };

  const loadProfissionaisExterno = async ({
    isClearable,
    totalElementsPageSize,
    statusAlterado,
    searchClean,
    sortDir,
    isPrint,
  }) => {
    try {
      setLoading(true);

      const response = await findAllProfissionalExternoAtivo({
        search: searchClean ? "" : search,
        pageableDTO: {
          ...pageableDTO,
          sortDir: sortDir ? sortDir : pageableDTO.sortDir,
          pageSize: totalElementsPageSize || 30,
        },
        ativo: statusAlterado ? statusAlterado?.value : status.value,
      });

      const { content, last, totalElements } = response;

      if (isPrint) {
        setProfissionaisExternosImpressao(content);
      } else {
        setProfissionaisExternos(content);
        setTotalElements(totalElements);
        setLast(last);
        setPageableDTO({
          ...pageableDTO,
          sortDir: sortDir ? sortDir : pageableDTO.sortDir,
          pageNumber: isClearable ? 0 : pageableDTO.pageNumber + 1,
        });
      }
    } catch {
      setNotification({
        open: true,
        message: "Erro ao carregar os profissionais externos",
        type: "error",
      });
    }
  };

  const handleClickOrdenar = (value) => {
    const sortDir =
      pageableDTO.sortField !== value
        ? "ASC"
        : pageableDTO.sortDir === "ASC"
        ? "DESC"
        : "ASC";

    loadProfissionaisExterno({ isClearable: true, sortDir });
  };

  const onClickSwitch = async (profissionalExterno) => {
    try {
      const { id } = profissionalExterno;
      profissionalExterno.ativo
        ? await inativarProfissionalSaudeExterno(id)
        : await ativarProfissionalSaudeExterno(id);

      setNotification({
        open: true,
        message: "Status alterado com sucesso",
        type: "success",
      });
      loadProfissionaisExterno({ isClearable: true });
    } catch {
      setNotification({
        open: true,
        message: "Erro ao alterar o status do profissional externo",
        type: "error",
      });
    }
  };

  const openNotification = ({ message, type }) => {
    setNotification({
      open: true,
      type,
      message,
    });

    const timeoutId = setTimeout(() => {
      resetNotification();
      clearTimeout(timeoutId);
    }, 4000);
  };

  const resetNotification = () => {
    setNotification(notificationDefault);
  };

  const closeAlertMessage = () => {
    setNotification(notificationDefault);
  };

  const handleClickClear = () => {
    setSearch("");
    loadProfissionaisExterno({ isClearable: true, searchClean: true });
  };

  const handleClickSearch = () => {
    loadProfissionaisExterno({ isClearable: true });
  };

  const handleCloseModal = () => {
    setProfissionalExternoSelecionado(profissionalExternoDefault);
    toggleModalProfissionalExterno();
  };

  const handlePrint = async () => {
    await loadProfissionaisExterno({
      isClearable: true,
      totalElements,
      isPrint: true,
    });

    setIsPrintMustache(true);
  };

  const handleStatus = (event) => {
    setStatus(event);
    loadProfissionaisExterno({ isClearable: true, statusAlterado: event });
  };

  return (
    <div className={classes.container}>
      <PageTitle title={pageConfiguracoes("Profissionais externos")} />
      <HeaderConfiguracoesButtonNovo
        title="Profissionais externos"
        labelButton="Novo profissional"
        handleClickNovo={handleClickOpenModalProfissionalExterno}
        showStatus
        status={{
          options: optionsStatus,
          value: status,
          onChange: handleStatus,
        }}
        search={{
          handleChange: handleSearchChange,
          value: search,
          onKeypress: onKeypressSearch,
          handleClickClear: handleClickClear,
          onClickButtonSearch: handleClickSearch,
        }}
      />
      <div className={classes.tableContainer}>
        <Scroll
          loadMore={loadProfissionaisExterno}
          hasMore={!last}
          pageStart={0}
          initialLoad={false}
        >
          <Table
            dados={profissionaisExternos}
            columns={columns({ onClickSwitch })}
            clickable={true}
            getDadosRow={handleClickOpenModalProfissionalExterno}
            ordenarTabela={{
              sortField: pageableDTO.sortField,
              sortDir: pageableDTO.sortDir,
            }}
            comOrdenacao
            handleClickOrdenar={handleClickOrdenar}
          />
        </Scroll>
        {!loading && profissionaisExternos.length === 0}
      </div>
      <div className={classes.contentButtons}>
        <Button
          shape={"circle"}
          onClick={handleClickOpenModalProfissionalExterno}
        >
          <Add />
        </Button>
        <Button
          shape={"circle"}
          onClick={handlePrint}
          bgColor={"#F9BE73"}
          disabled={profissionaisExternos?.length === 0}
        >
          <PrintIcon />
        </Button>
      </div>
      {open && (
        <ModalProfissionalExterno
          open={open}
          onClose={handleCloseModal}
          profissionalExternoSelecionado={profissionalExternoSelecionado}
          openNotification={openNotification}
          loadProfissionaisExterno={loadProfissionaisExterno}
        />
      )}
      {isPrintMustache && (
        <ImpressaoHtml
          isPrintMustache={isPrintMustache}
          handlePrintMustache={() => setIsPrintMustache(false)}
          htmlStringComponent={
            <ProfissionaisExternosImpressao
              dadosRelatorio={profissionaisExternosImpressao || []}
              filters={{
                search,
                ativo: status,
              }}
            />
          }
        />
      )}
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.open}
        variant={notification.type}
        message={notification.message}
      />
    </div>
  );
};

export default withStyles(styles)(ProfissionaisExternos);
