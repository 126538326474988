import Colors from "../../../template/Colors";

const styles = {
  wrapperParcelamentoTab: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginTop: "8px",
    maxHeight: "calc(100% - 40px)",
  },
  tituloModal: {
    fontSize: 18,
    color: Colors.commons.secondary,
    fontWeight: 700,
  },
  fullSizePaper: {
    borderRadius: "8px",
    maxWidth: "836px",
    display: "flex",
    flexDirection: "row",
    maxHeight: "600px",
    height: "100%",
    overflow: 'hidden'
  },
  contentLancamento: {
    width: "100%",
    maxWidth: "505px",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    height: "64px",
    padding: "0 32px",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  dialogContent: {
    padding: "16px",
    height: "calc(100% - 104px)",
    overflow: "hidden",
  },
  contentParcelar: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
  },
  contentDados: {
    overflow: 'auto',
    height: "calc(100% - 70px)",
  },
  contentButton: {
    display: "flex",
    gap: "8px",
  },
  buttonHeader: {
    width: "40px",
    height: "40px",
    color: Colors.commons.white,
  },
  buttonPrint: {
    backgroundColor: Colors.commons.yellow,
    "&:hover": {
      backgroundColor: Colors.commons.darkYellow,
    },
  },
  buttonSave: {
    backgroundColor: Colors.commons.green,
    width: 80,
  },
  buttonExcluir: {
    backgroundColor: Colors.commons.red,
    "&:hover": {
      backgroundColor: Colors.commons.darkRed,
    },
  },
  buttonClose: {
    width: 32,
    height: 32,
    background: "#fff",
    borderRadius: "100px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: Colors.commons.grayLight,
    },
  },
  buttonEdit: {
    background: "#707C97",
  },
  closeIcon: {
    color: "#219A97",
  },
  toggleButtons: {
    marginRight: 0,
  },
  contentParcelamento: {
    padding: "12px",
    background: "#FFFFFF",
    borderRadius: "16px",
    flex: 1,
  },
  contentTab: {
    height: "calc(100% - 82px)",
    margin: "8px 0",
    overflowY: "auto",
    overflowX: "hidden",
  },
  contentTabAuditorias: {
    marginTop: "16px",
    overflow: "auto",
  },
  contentModal: {
    height: "100%",
  },
  contentTabParcelamento: {
    display: "flex",
    height: "calc(100% - 36px)",
  },
  notFoundContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  parcelamento: {
    maxWidth: 300,
    width: "100%",
    backgroundColor: Colors.commons.secondary,
    padding: "19px 15px 18px 16px",
  },
  titleParcelamento: {
    color: "#fff",
    fontWeight: 700,
    fontSize: "18px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleField: {
    color: "#868686",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginBottom: "2px",
  },
  inputSearch: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  notchedOutlineSearch: {
    border: "none",
  },
  row: {
    marginTop: 4,
  },
  contentField: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
  },
  contentFieldDescricao: {
    display: "flex",
    flexDirection: "column",
  },
  inputValor: {
    width: "calc(100% - 10px)",
    background: "#F2F2F2",
    borderRadius: "8px",
    height: "30px",
    paddingLeft: "8px",
  },
  marginTop: {
    marginTop: "8px",
  },
  inputDate: {
    background: "#F2F2F2",
    border: "0.5px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
    "& input": {
      textAlign: "center",
      fontFamily: "Poppins",
    },
  },
  descricao: {
    "& textarea": {
      height: "100%",
      borderRadius: "8px",
    },
    "&> div": {
      padding: 0,
    },
  },
  selectParcelas: {
    width: "100%",
    "&>div>div": {
      height: 32,
      borderRadius: "8px",
      display: "flex",
      alignContent: "center",
      background: "#f2f2f2",
    },
  },
  fieldDesconto: {
    alignSelf: "end",
  },
  selectPeriodicidade: {
    width: "calc(100% - 2px)",
  },
  fieldParcelas: {
    maxWidth: "68px",
  },
  rowParcela: {
    display: "flex",
    gap: "8px",
    alignItems: "end",
  },
  fieldDisabled: {
    backgroundColor: "#f2f2f2",
    height: 32,
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    color: "#333333",
  },
  inputFormQuantidade: {
    width: "100%",
    borderRadius: '8px'
  },
  circularProgressStyle: {
    color: "#fff",
  },
  notFoundContainerParcela: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  vencimento: {
    whiteSpace: "nowrap",
  },
  valorDesconto: {
    alignSelf: "end",
  },
  subtotal: {
    height: "32px",
    display: "flex",
    alignItems: "center",
  },
  inputPercentil: {
    height: 32,
    borderRadius: "8px",
    display: "flex",
    alignContent: "center",
    background: "#f2f2f2",
  },
  inputRootPercentil: {
    border: "1px solid rgba(220, 220, 220, 0.2)",
    height: "30px",
    fontFamily: "Poppins",
    fontSize: "14px",
  },
  rowMarginParcelar: {
    marginTop: "10px",
  },
  rowParcelar: {
    display: "flex",
    gap: "6px",
    flexDirection: "row",
    marginTop: "8px",
  },
  inputValorParcelar: {
    background: "#fff",
    height: "32px",
    display: "flex",
    alignItems: "center",
  },
  rowQuantidade: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  },
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  titleSemQuebraLinha: {
    whiteSpace: 'nowrap',
  },
  fullWidth: {
    width: '100%'
  },
  buttonsTab: {
    margin: '16px 8px 8px'
  },
  dadosRepetir: {
    overflow: 'auto',
    height: "calc(100% - 70px)",
  },
  selectConta: {
    maxHeight: '86px',
    "&>div": {
      maxWidth: '87px',
    },
  },
  inputError: {
    border: '1px solid red',
    borderRadius: '8px'
  },
  contentInfo: {
    display: 'flex',
    alignItems: 'start',
    gap: '8px',
    padding: '4px 8px',
    margin: '8px 0',
    borderRadius: '28px',
    border: '1px solid rgba(220, 220, 220, 0.2)',
    background: '#F2F2F2',
    color: '#219A97',
    '& a': {
      textDecoration: 'underline',
      color: '#219A97',
      fontWeight: '700',
      cursor: 'pointer',
    },
  },
};

export default styles;
