import { inject } from "mobx-react";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState, Suspense, lazy } from "react";
import { CircularProgress, withStyles } from "@material-ui/core";
import styles from "./styles";
import Dialog from "../../../components/Dialog/Dialog";
import CloseIcon from "../../../components/Icon/Close";
import { Button } from "../../../components/ui/Buttons";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { pageConfiguracoes } from "../../../utils/getPageTitle";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import { findAllProfissionalSaudeAuditByProfissionalSaudeId } from "../../../services/ProfissionaisExternosService";

const Auditoria = lazy(() => import('../../../components/Auditoria/Auditoria'));
const InformacoesProfissonalExterno = lazy(() => import('./InformacoesProfissionalExterno'));

const ModalProfissionalExterno = observer(
  ({
    classes,
    auditoriaStore,
    open,
    onClose,
    profissionalExternoSelecionado,
    openNotification,
    loadProfissionaisExterno,
  }) => {
    const [tabSelected, setTabSelected] = useState(0);

    useEffect(() => {
      auditoriaStore.auditoriaProps = {
        ...auditoriaStore.auditoriaProps,
        auditoria: "o profissional externo",
        auditoriaTela: "profissionalExterno",
      };
    }, []);

    const isEdit = profissionalExternoSelecionado?.id;
    const getPageTitle = () => {
      const acao = isEdit ? "Editar" : "Novo";

      return pageConfiguracoes(`${acao} profisisonal externo`);
    };

    const changeTabSelected = async (tab) => {
      if (tab === 1) {
        auditoriaStore.auditoriaProps = {
          ...auditoriaStore.auditoriaProps,
          pageNumber: 0,
          lastPage: false,
          auditorias: [],
        };

        auditoriaStore.load = {
          query: findAllProfissionalSaudeAuditByProfissionalSaudeId,
          variableName: "profissionalSaudeId",
          variableId: profissionalExternoSelecionado.id,
        };
        await auditoriaStore.loadAuditItems();
      }
      setTabSelected(tab);
    };

    const renderTab = () => {
      return tabSelected === 0 ? (
        <InformacoesProfissonalExterno
          onClose={onClose}
          profissionalExternoSelecionado={profissionalExternoSelecionado}
          openNotification={openNotification}
          loadProfissionaisExterno={loadProfissionaisExterno}
        />
      ) : (
        <Auditoria />
      );
    };

    return (
      <>
        <PageTitle title={getPageTitle()} />
        <Dialog
          open={open}
          onClose={onClose}
          classes={{
            paper: classes.dialogPaper,
          }}
          fullWidth={true}
        >
          <div className={classes.modalHeader}>
            <div className={classes.titleModal}>
              {isEdit
                ? "Editar profissional externo"
                : "Novo profissional externo"}
            </div>
            <Button
              shape="circle"
              bgColor="#FB7676"
              style={{ height: 32, width: 32 }}
              onClick={onClose}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className={classes.containerModal}>
            <MultiToggleButtons
              options={["Informações", "Auditoria"]}
              tabSelected={tabSelected}
              changeTabSelected={changeTabSelected}
              classes={{ buttonsContainer: classes.toggleButtons }}
              disabled={!profissionalExternoSelecionado?.id}
              posicaoDesabilitada={[1]}
            />
            <div className={classes.contentModal}>
              <Suspense fallback={<CircularProgress size={32} />}>
                {renderTab()}
              </Suspense>
            </div>
          </div>
        </Dialog>
      </>
    );
  }
);

const ModalProfissionalExternoWithStyles = withStyles(styles)(
  ModalProfissionalExterno
);
export default inject("auditoriaStore")(ModalProfissionalExternoWithStyles);
