const styles = (theme) => ({
  tableContainer: {
		display: "flex",
    flexDirection: 'column',
		padding: "2% 4%",
    backgroundColor: '#F5F5F5',
    zIndex: 0,
    overflow: 'scroll'
	},
  header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
    columnGap: '16px',
    marginBottom: '16px',
    "@media(max-width: 1280px)": {
      flexDirection: 'column',
      alignItems: 'flex-start',
      rowGap: '16px'
    },
	},
  titleHeader: {
		fontSize: "1.2rem",
		fontWeight: "bold",
		color: theme.palette.primary.main,
		whiteSpace: "nowrap",
	},
  headerTitleRoot: {
		maxWidth: theme.commons.menuLeft.width,
		margin: "0",
	},
  pageDescription: {
		fontSize: '14px !important',
		fontWeight: '400 !important',
		color: '#333333',
	},
  valueContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '70px',
    width: '348px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
    backgroundColor: '#FFF',
    columnGap: '4px'
  },
  valueLabel: {
    color: '#505050',
    fontSize: '18px !important',
    fontWeight: '700 !important'
  },
  value: {
    color: '#9871FC',
    fontSize: '18px !important',
    fontWeight: '700 !important'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#FFF',
    borderRadius: '16px',
  },
  contentInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '16px',
    borderRadius: '8px',
    marginBottom: '16px',
    backgroundColor: '#E1F3F4',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    fontSize: '16px',
    fontWeight: '600',
    color: '#505050',
  }
});

export default styles;
