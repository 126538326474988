import React, { useEffect, useRef, useState } from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';
import ItemGrid from '../../../components/Modal/ItemGridCard';
import { InputCNPJForm, InputForm, InputPhoneForm } from "../../../components/Modal/Input";
import { accountTypeTabs, documentTypeTabs, braspagSituacoes } from '../constants/recebimentosViziConstants';
import TabSelector from '../../../components/TabSelector';
import {Button} from '../../../components/ui/Buttons';
import { findAllBanco } from '../../../services/ContaBancariaService';
import { TextFieldSearch } from '../../../components/TextField';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import string from '../../../utils/string';
import { InputCEPForm } from '../../../components/Input/InputCEPForm';
import colors from '../../../template/Colors';
import { SelectSearch } from '../../../components/Select/SelectSearchAsync';
import DadosInsuficientesWarning from "../components/DadosInsuficientesWarning";
import StatusWithCircleLabel from '../../../components/Labels/StatusWithCircleLabel';
import { supportWhatsappLink } from '../constants/perfilViziConstants';
import { consultaDeCep } from '../../../services/SujeitoAtencaoService';

import ListFilesSelected from './ListFilesSelected';
import { createDadosBraspagAnexo } from '../../../services/ProfissionalSaudeBraspagService';
import { uploadArquivos } from '../../../utils/uploadArquivo';
import { getWhitelabelNome } from '../../../services/WhitelabelService';


const TYPE_FILE = {
  DOMICILIO_BANCARIO: 'DOMICILIO_BANCARIO',
  MODEL_OF_ADHESION_TERM: 'MODEL_OF_ADHESION_TERM',
  PROOF_BUSINESS_ACTIVITY: 'PROOF_BUSINESS_ACTIVITY'
}

const styles = ({
  page: {
    height: '100%',
    padding: '0px 16px 16px 16px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItem: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  headerTextArea: {
    display: 'flex',
    flexDirection: 'column'
  },
  headerTitle: {
    fontSize: '20px !important',
    fontWeight: '600'
  },
  headerText: {
    color: '#333333'
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '62% 35%',
    gridGap: '16px',
    '@media (max-width: 1030px)': {
      display: 'flex',
      flexDirection: 'column-reverse'
    }

  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    padding: '16px 36px 16px 16px',
    borderRadius: '8px'
  },
  formSectionLabel: {
    color: '#505050',
    fontSize: '16px !important',
    fontWeight: '700',
  },
  formSection: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    width: '100%',
    rowGap: '8px',
  },
  fieldWithDigit: {
    display: 'grid',
    gridTemplateColumns: '75% auto',
    gridGap: '10px'
  },
  infoDigito: {
    fontSize: '10px',
    color: '#868686',
  },
  fieldWithDigitAgencia: {
    gridTemplateColumns: '50% 23% 22%',
  },
  input: {
    width: 'calc( 100% - 20px )',
		background: '#F2F2F2',
		color: '#505050',
		borderRadius: '8px',
		height: '32px',
		padding: '4px 8px',
  },
  inputContainer: {
    width: "100%",
    border: 'none',
    borderRadius: '10px',
    paddingLeft: '8px',
    backgroundColor: '#F2F2F2',
    fontSize: '14px',
    color: colors.commons.gray9,
    '&:focus': {
      borderColor: colors.primary.main
    }
  },
  inputError: {
    border: 'solid 1px red'
  },
  warningCard: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    backgroundColor: '#E3F4FE',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    color: '#2E6483',
    padding: '16px'
  },
  warningMainText: {
    fontWeight: '600 !important',
    fontSize: '16px !important',
    lineHeight: '22px',
    marginBottom: '10px'
  },
  infoIcon: {
    color: '#2E6483'
  },
  notchedOutline: {
    border: 0
  },
  withoutArrows: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      display: "none"
    }
  },
  enderecoContainer: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '75% auto',
    gridGap: '10px'
  },
  bairroContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    columnGap: '10px',
  },
  splittedField: {
    display: 'grid',
    gridTemplateColumns: '45% auto',
    gridGap: '10px'
  }, 
  customWidth: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '40px'
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px'
  },
  municipioEstadoSeletors: {
    width: '108px'
  },
  suppContactMessage: {
    fontSize: '12px !important',
    color: '#868686',
    fontWeight: '400'
  },
  buttonFile: {
    width: '100%', 
    margin: '16px 0', 
    padding: '6px 0'
  },
  whatsappLink: {
    color: '#868686',
    margin: '0 .5rem',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  camposOpcionaisLegenda:{
    fontSize:'10px !important',
    color:'#505050'

  }
});

const buttonStyle = {
  height: '36px',
  width: '250px',
  borderRadius: '100px',
  textTransform: 'none'
};

const DadosBancarios = observer(({ 
  classes,
  unidadeStore,
  braspagStore,
  dadosBraspag,
  showAlertMessage,
  loadAnexosDadosBraspag,
  whitelabelStore
}) => {
  const form = braspagStore[dadosBraspag];
  const { 
    formErrors, 
    saving:saveButtonDisabled, 
    dadosBancariosHasError, 
    validateDadosBancarios 
  } = braspagStore;
  const { configuracaoWhitelabel } = whitelabelStore;
  const { taxaAdministrativa } = configuracaoWhitelabel?.taxasIugu || {};

  const [braspagCurrentStatus, setBraspagCurrentStatus] = useState(null);
  const [files, setFiles] = useState({
    [TYPE_FILE.DOMICILIO_BANCARIO]: [],
    [TYPE_FILE.MODEL_OF_ADHESION_TERM]: [],
    [TYPE_FILE.PROOF_BUSINESS_ACTIVITY]: []
  });
  const [loadingFile, setLoadingFile] = useState({
    [TYPE_FILE.DOMICILIO_BANCARIO]: false,
    [TYPE_FILE.MODEL_OF_ADHESION_TERM]: false,
    [TYPE_FILE.PROOF_BUSINESS_ACTIVITY]: false
  });

  const proofResidenceFileInput = useRef(null);
  const identificationFileInput = useRef(null);
  const adhesionFileInput = useRef(null);

  const isProfissional = dadosBraspag === 'profissionalSaudeDadosBraspag';

  useEffect(() => {
    if (form.dadoBancario && !braspagStore.loading) {
      validateDadosBancarios();
    }
  }, [braspagStore[dadosBraspag].dadoBancario]);

  useEffect(() => {
    getCurrentBraspagStatus(braspagStore[dadosBraspag].status);
  }, [braspagStore[dadosBraspag].status]);

  useEffect(() => {
    const tiposDeDocumento = [TYPE_FILE.DOMICILIO_BANCARIO, TYPE_FILE.MODEL_OF_ADHESION_TERM, TYPE_FILE.PROOF_BUSINESS_ACTIVITY];
    const arquivosFiltrados = tiposDeDocumento.reduce((result, tipo) => {
      result[tipo] = braspagStore[dadosBraspag].dadosBraspagAnexo.filter((item) => item.documentoTipo === tipo && !item.descartado);
      return result;
    }, {});
    setFiles(arquivosFiltrados);
  }, [braspagStore[dadosBraspag].dadosBraspagAnexo]);

  useEffect(() => {
    getConfiguracaoWhitelabel();
  }, [])

  const getConfiguracaoWhitelabel = async () => {
    await whitelabelStore.getConfiguracaoWhitelabel();
  }

  const DadoBancarioInsuficiente = <span>Para ativar o <b>recebimento online</b> e <b>teleconsulta</b> é necessário preencher os dados bancários. Após o preenchimento terá um prazo máximo de 03 dias úteis após o envio para validação.</span>

  const onChangeForm = (value, field) => {
    if (field === 'documento') {
      braspagStore[dadosBraspag] = {
        ...braspagStore[dadosBraspag],
        [field]: value,
        dadoBancario: {
          ...braspagStore[dadosBraspag].dadoBancario,
          [field]: value,
        }
      }
      return;
    }

    if (field === 'digitoVerificador') {
      const apenasNumerosELetraX = value === "" || value === "x" || /[0-9]/.test(value);

      if(apenasNumerosELetraX) {
        braspagStore[dadosBraspag] = {
          ...form,
          dadoBancario: {
            ...form.dadoBancario,
            [field]: value,
          }
        } 
      }
      return;
    }

    if (field === 'nomeFantasia' || field === 'razaoSocial') {
      braspagStore[dadosBraspag] = {
        ...braspagStore[dadosBraspag],
        [field]: value
      }
    } else {
      braspagStore[dadosBraspag] = {
        ...braspagStore[dadosBraspag],
        dadoBancario: {
          ...braspagStore[dadosBraspag].dadoBancario,
          [field]: value,
        }
      }
    }
  }

  const handleSelectTypeAccount = (index) => {
    if (index === 0) {
      onChangeForm('CONTA_CORRENTE', 'tipoConta')
    } else {
      onChangeForm('CONTA_POUPANCA', 'tipoConta')
    }
  };

  const handleSelectDocumentType = (index) => {
    if (index !== 0) {
      onChangeDocumentType('CNPJ');
    } else {
      onChangeDocumentType('CPF');
    }
  }

  const handleSelectBank = (value) => {
    onChangeForm(value, 'banco');
  }

  const handleSaveBankAccount = () => {
    if (form.documentoTipo === 'CPF') {
      delete form.razaoSocial;
      delete form.nomeFantasia;
    }
    handleSave();
  }

  const loadBanks = async (search, loadOptions, pageOptions) => {
    try {
      const result = await findAllBanco(search, pageOptions.page);
      if (result.data.data.findAllBanco) {
        const { content, lastPage } = result.data.data.findAllBanco;
        return {
          options: content,
          hasMore: !lastPage
        };
      } else {
        return {
          options: [],
          hasMore: false
        };
      }
    } catch (error) {
      console.log(error);
    }
  };


  const onChangeEnderecoForm = (value, field) => {
    braspagStore[dadosBraspag].endereco[field] = value;
    if (field === 'estado') {
      onChangeEnderecoForm(null, 'municipio');
      unidadeStore.getMunicipioByEstado(value.value);
    }
  };

  const onChangeCep = async (cep) => {
    braspagStore[dadosBraspag].endereco.cep = cep;
    const cepSemMascara = cep.replace(/\D/g, '');
    if (cepSemMascara.length === 8) {
      try {
        const response = await consultaDeCep(cepSemMascara);
        if (response?.data?.data?.consultaDeCep) {
          const { logradouro, bairro, municipio } = response.data.data.consultaDeCep;
          braspagStore[dadosBraspag].endereco = {
            ...braspagStore[dadosBraspag].endereco,
            numero: '',
            nomeLogradouro: logradouro,
            bairro,
            municipio: {
              value: municipio.id,
              label: municipio.nome,
            },
            estado: {
              value: municipio.estado.id,
              label: municipio.estado.nome
            }
          };
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
  
  const onChangeDocumentType = (documentType) => {
    braspagStore[dadosBraspag].documentoTipo = documentType;
  };

  const onChangeContato = (value, field) => {
    braspagStore[dadosBraspag][field] = value;
  };

  const handleSave = async () => {
    try {
      await braspagStore.updateBraspag();
      braspagStore.openNotification(true, 'Dados bancários atualizados com sucesso!', 'success');
    } catch (error) {
      braspagStore.openNotification(true, error.message, 'error')
    }
  };

  const getCurrentBraspagStatus = (status) => {
    if (!status) {
      status = 'NOTSTARTED'
    }

    const item = braspagSituacoes.filter((statusItem) => status === statusItem.value)[0];
    setBraspagCurrentStatus(item);
  }

  const handleOpenFileSelector = (ref) => {
    ref.current.click();
  }

  const handleChangeFile = async (event, field) => {
    const { files } = event.target;
    setLoadingFile({
      ...loadingFile,
      [field]: true
    });
    try {
      const arquivos = await uploadArquivos(files);
      delete arquivos[0].tamanho;
      await createDadosBraspagAnexo({
        documentoTipo: field,
        arquivoTipo: files[0].type === 'application/pdf' ? 'ARQUIVO' : 'IMAGEM'
      }, arquivos[0]);
      await loadAnexosDadosBraspag();
    } catch (error) {
      console.error(error);
      showAlertMessage('Ocorreu um erro ao tentar enviar o arquivo.', 'error');
    } finally {
      setLoadingFile({
        ...loadingFile,
        [field]: false
      });
    }
  }


  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <div className={classes.headerTextArea}>
          <span className={classes.headerTitle}>Dados de Recebimento</span>
          <span className={classes.headerText}>Cadastre abaixo a conta em que você deseja receber as cobranças geradas plataforma {getWhitelabelNome()}.</span>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.formSection}>
            <span className={classes.formSectionLabel}>Dados bancários</span>
            <ItemGrid label="Tipo de documento" bold>
              <TabSelector 
                tabsOptions={documentTypeTabs(isProfissional, braspagStore[dadosBraspag])}
                baseColor="#F2F2F2"
                selectedColor="#FFF"
                borderRadius="8px"
                onSelect={handleSelectDocumentType}
                selectedTabIndex={form.documentoTipo === 'CPF' || !isProfissional ? 0 : 1}
              />
            </ItemGrid>
            {form.documentoTipo === 'CNPJ' && (
              <>
                <div className={classes.splittedField}>
                  <ItemGrid label="CNPJ" bold>
                    <InputCNPJForm
                      className={classNames(classes.input, formErrors.documento ? classes.inputError : null)}
                      value={form.dadoBancario.documento}
                      onChange={(e) => onChangeForm(string.removeSpecialChars(e.target.value), 'documento')}
                      placeholder="CNPJ"
                    />
                  </ItemGrid>
                  <ItemGrid label="Razão Social" bold>
                    <InputForm
                      className={classNames(classes.input, formErrors.razaoSocial ? classes.inputError : null)}
                      value={form.razaoSocial}
                      onChange={(e) => onChangeForm(e.target.value, 'razaoSocial')}
                      placeholder="Razão social"
                    />
                  </ItemGrid>
                </div>
                <ItemGrid label="Nome Fantasia" bold>
                  <InputForm
                    className={classNames(classes.input, formErrors.nomeFantasia ? classes.inputError : null)}
                    value={form.nomeFantasia}
                    onChange={(e) => onChangeForm(e.target.value, 'nomeFantasia')}
                    placeholder="Nome fantasia"
                  />
                </ItemGrid>
              </>
            )}
          </div>
          <div className={classes.formSection}>
            <span className={classes.formSectionLabel}>Dados Bancários</span>
            <ItemGrid size={12} label="Banco" bold>
              <TextFieldSearch
                placeholder="Selecione um banco"
                value={form.dadoBancario?.banco}
                onChange={handleSelectBank}
                loadOptions={loadBanks}
                className={classNames(classes.input, formErrors.banco ? classes.inputError : null, classes.customWidth)}
                classNotched={classes.notchedOutline}
                withPaginate
                debounceTimeout={1000}
                additional={{
                  page: 0,
                }}
                getOptionLabel={(option) => `${option.codigo} - ${option.nome}`}
                getOptionsValue={(option) => option.id}
              />
            </ItemGrid>
            <ItemGrid size={12} label="Tipo de Conta" bold>
              <TabSelector 
                tabsOptions={accountTypeTabs}
                baseColor="#F2F2F2"
                selectedColor="#FFF"
                onSelect={handleSelectTypeAccount}
                selectedTabIndex={form.dadoBancario.tipoConta === 'CONTA_CORRENTE' ? 0 : 1}
                borderRadius="8px"
                />
            </ItemGrid>
            <div className={classes.fieldWithDigit}>
              <ItemGrid size={12} label="Número da Conta" bold>
                <InputForm
                  className={classNames(classes.input, formErrors.conta ? classes.inputError : null)}
                  value={form.dadoBancario.conta}
                  onChange={(e) => onChangeForm(e.target.value, 'conta')}
                  placeholder="Número da conta"
                  type="number" 
                  onInput={(e) => e.target.value = e.target.value.slice(0, 10)}
                  alternativeInputClass={classes.withoutArrows}
                  />
              </ItemGrid>
              <ItemGrid size={12} label="Dígito*" bold>
                <InputForm
                  className={classNames(classes.input, formErrors.digitoVerificador ? classes.inputError : null)}
                  value={form.dadoBancario.digitoVerificador}
                  onChange={(e) => onChangeForm(e.target.value, 'digitoVerificador')}
                  placeholder="Dígito da conta"
                  onInput={(e) => e.target.value = e.target.value.slice(0, 1)}
                  alternativeInputClass={classes.withoutArrows}
                />
              </ItemGrid>
            </div>
            <div className={classNames(classes.fieldWithDigit, classes.fieldWithDigitAgencia)}>
              <ItemGrid size={12} label="Agência" bold>
                <InputForm
                  className={classNames(classes.input, classes.withoutArrows, formErrors.agencia ? classes.inputError : null)}
                  value={form.dadoBancario.agencia}
                  onChange={(e) => onChangeForm(e.target.value, 'agencia')}
                  placeholder="Número da agência"
                  type="number" 
                  onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                  alternativeInputClass={classes.withoutArrows}
                />
              </ItemGrid>
              <ItemGrid size={12} label="Dígito*" bold>
                <InputForm
                  className={classNames(classes.input, formErrors.digitoAgencia ? classes.inputError : null)}
                  value={form.dadoBancario.digitoAgencia}
                  onChange={(e) => onChangeForm(e.target.value, 'digitoAgencia')}
                  placeholder="Dígito da agência"
                  type="number" 
                  onInput={(e) => e.target.value = e.target.value.slice(0, 1)}
                  alternativeInputClass={classes.withoutArrows}
                  />
              </ItemGrid>
              <ItemGrid size={12} label="Operação" bold>
                <InputForm
                  className={classNames(classes.input)}
                  value={form.dadoBancario.operacao}
                  onChange={(e) => onChangeForm(e.target.value, 'operacao')}
                  placeholder="Operação"
                  onInput={(e) => e.target.value = e.target.value.slice(0, 2)}
                  alternativeInputClass={classes.withoutArrows}
                  />
              </ItemGrid>
            </div>
            <span className={classes.camposOpcionaisLegenda}>*campos opcionais</span>
          </div>
          <div className={classes.formSection}>
            <span className={classes.formSectionLabel}>Infos de Contato</span>
              <ItemGrid label="Nome do Contato" bold>
                <InputForm
                  className={classNames(classes.input, formErrors.responsavelNome ? classes.inputError : null)}
                  value={form.responsavelNome}
                  onChange={(e) => onChangeContato(e.target.value, 'responsavelNome')}
                  placeholder="Nome do Contato"
                />
              </ItemGrid>
              <ItemGrid label="Telefone" bold>
                <InputPhoneForm
                  placeholder={"Telefone"}
                  className={classNames(classes.input, formErrors.responsavelTelefone ? classes.inputError : null)}
                  onChange={(e) =>
                    onChangeContato(e.target.value, 'responsavelTelefone')
                  }
                  value={form.responsavelTelefone || ''}
                />
              </ItemGrid>
            <div className={classes.bairroContainer}>
              <ItemGrid label="E-Mail" bold style={{ flex: 1 }}>
                <InputForm
                  name="E-mail:"
                  placeholder="E-mail"
                  className={classNames(classes.input, formErrors.email ? classes.inputError : null)}
                  value={form.email}
                  onChange={(e) => onChangeContato(e.target.value, 'email')}
                />
              </ItemGrid>
            </div>
          </div>
          <div className={classes.formSection}>
            <span className={classes.formSectionLabel}>Endereço</span>
            <ItemGrid size={12} label="CEP" bold>
              <InputCEPForm
                placeholder="CEP"
                showmask={'false'}
                value={form.endereco?.cep}
                className={classNames(classes.input, formErrors.cep ? classes.inputError : null)}
                classes={{
                  input: classes.inputContainer,
                }}
                onChange={(e) => onChangeCep(e.target.value)}
              />
            </ItemGrid>
            <div className={classes.enderecoContainer}>
              <ItemGrid label="Rua" bold>
                <InputForm
                  className={classNames(classes.input, formErrors.nomeLogradouro ? classes.inputError : null)}
                  value={form.endereco?.nomeLogradouro}
                  onChange={(e) => onChangeEnderecoForm(e.target.value, 'nomeLogradouro')}
                  placeholder="Rua"
                />
              </ItemGrid>
              <ItemGrid label="Número" bold>
                <InputForm
                  className={classNames(classes.input, formErrors.numero ? classes.inputError : null)}
                  value={form.endereco?.numero}
                  onChange={(e) => onChangeEnderecoForm(e.target.value, 'numero')}
                  placeholder="Número"
                />
              </ItemGrid>
            </div>
            <div className={classes.bairroContainer}>
              <ItemGrid label="Bairro" bold style={{ flex: 1 }}>
                <InputForm
                  className={classNames(classes.input, formErrors.bairro ? classes.inputError : null)}
                  value={form.endereco?.bairro}
                  onChange={(e) => onChangeEnderecoForm(e.target.value, 'bairro')}
                  placeholder="Bairro"
                />
              </ItemGrid>
              <ItemGrid label="Estado" bold>
                <SelectSearch
                  name={"estado"}
                  placeholder={"Estado"}
                  value={form.endereco?.estado}
                  className={classNames(classes.input, classes.municipioEstadoSeletors)}
                  onChange={(e) => onChangeEnderecoForm(e, 'estado')}
                  elements={unidadeStore.estados}
                />
              </ItemGrid>
              <ItemGrid label="Município" bold>
                <SelectSearch
                  name={"municipio"}
                  placeholder={"Município"}
                  value={form.endereco?.estado ? form.endereco?.municipio : null}
                  disabled={!form.endereco?.estado}
                  className={classNames(classes.input, classes.municipioEstadoSeletors)}
                  onChange={(e) => onChangeEnderecoForm(e, 'municipio')}
                  elements={unidadeStore.municipios}
                />
              </ItemGrid>
            </div>
            <ItemGrid label="Complemento" bold>
              <InputForm
                className={classNames(classes.input)}
                placeholder="Complemento"
                value={form.endereco?.complemento}
                onChange={(e) => onChangeEnderecoForm(e.target.value, 'complemento')}
              />
            </ItemGrid>
          </div>
          {isProfissional && form.documentoTipo === 'CPF' && (
            <div className={classes.formSection}>
              <span className={classes.formSectionLabel}>Envio de Documentos (Anexos)</span>
              <ItemGrid label="Comprovante de atividade empresarial" bold>
                {formErrors.dadosBraspagAnexo && (
                  <span style={{color: 'red', fontSize: '12px'}}>
                    Necessário anexar o comprovante de atividade empresarial
                  </span>
                )}
                  <ListFilesSelected 
                    files={files[TYPE_FILE.PROOF_BUSINESS_ACTIVITY]} 
                    showAlertMessage={showAlertMessage} 
                    loading={loadingFile[TYPE_FILE.PROOF_BUSINESS_ACTIVITY]} 
                    refetch={loadAnexosDadosBraspag}
                  />
                  <Button 
                    kind='secondary'
                    onClick={() => handleOpenFileSelector(proofResidenceFileInput)} 
                    className={classes.buttonFile} 
                  >
                    Selecionar arquivo
                  </Button>
                  <input
                    type="file"
                    onChange={(event) => handleChangeFile(event, TYPE_FILE.PROOF_BUSINESS_ACTIVITY)}
                    ref={proofResidenceFileInput}
                    style={{display: 'none'}} 
                  />
              </ItemGrid>
              <ItemGrid label="Domilício bancário" bold>
                  <ListFilesSelected 
                    files={files[TYPE_FILE.DOMICILIO_BANCARIO]} 
                    showAlertMessage={showAlertMessage} 
                    loading={loadingFile[TYPE_FILE.DOMICILIO_BANCARIO]} 
                    refetch={loadAnexosDadosBraspag}
                  />
                  <Button 
                    kind='secondary' 
                    onClick={() => handleOpenFileSelector(identificationFileInput)} 
                    className={classes.buttonFile} 
                  >
                    Selecionar arquivo
                  </Button>
                  <input
                    type="file"
                    onChange={(event) => handleChangeFile(event, TYPE_FILE.DOMICILIO_BANCARIO)}
                    ref={identificationFileInput}
                    style={{display: 'none'}} 
                  />
              </ItemGrid>
              <ItemGrid label="Modelo de termo de adesão" bold>
                  <ListFilesSelected 
                    files={files[TYPE_FILE.MODEL_OF_ADHESION_TERM]} 
                    showAlertMessage={showAlertMessage} 
                    loading={loadingFile[TYPE_FILE.MODEL_OF_ADHESION_TERM]} 
                    refetch={loadAnexosDadosBraspag}
                  />
                  <Button 
                    kind='secondary' 
                    onClick={() => handleOpenFileSelector(adhesionFileInput)} 
                    className={classes.buttonFile} 
                  >
                    Selecionar arquivo
                  </Button>
                  <input
                    type="file"
                    onChange={(event) => handleChangeFile(event, TYPE_FILE.MODEL_OF_ADHESION_TERM)}
                    ref={adhesionFileInput}
                    style={{display: 'none'}} 
                  />
              </ItemGrid>
            </div>
          )}
          <div>
            <Button
              style={buttonStyle}
              onClick={handleSaveBankAccount}
              disabled={saveButtonDisabled}
            >
              {saveButtonDisabled ? 
                <CircularProgress size={22} style={{color: '#FFF'}}/> 
                : 'Salvar conta'
              }
            </Button>
          </div>
        </div>
        <div className={classes.rightColumn}>
          <ItemGrid label="Situação">
            {braspagCurrentStatus && (
              <StatusWithCircleLabel
                item={braspagCurrentStatus}
              />
            )}
            {braspagCurrentStatus?.value === 'APPROVEDWITHRESTRICTION' && (
              <span className={classes.suppContactMessage}>
                Por favor, entrar em
                <a href={supportWhatsappLink} target='_blank' className={classes.whatsappLink} rel="noreferrer"><u><b>
                  contato com o suporte do App Health
                </b></u></a>
                para mais informações.
              </span>
            )}
          </ItemGrid>
          <div className={classes.warningCard}>
            <span className={classes.warningMainText}>
              Informação sobre as taxas cobradas sobre transações realizadas dentro da plataforma do {getWhitelabelNome()}.
            </span>
            <span>
              Será cobrada uma <b>taxa administrativa de {taxaAdministrativa || 6}%</b> sobre todas os pagamentos realizados por cartão de crédito, dentro do sistema {getWhitelabelNome()}, devido a taxa do gateway de pagamento.
            </span>
          </div>
          {dadosBancariosHasError && (
            <DadosInsuficientesWarning
              title="Preencha os dados bancários"
              message={DadoBancarioInsuficiente}
              withoutButton
            />
          )}
        </div>
      </div>
    </div>
  )
});

const DadosBancariosStyles = withStyles(styles)(DadosBancarios);
export default inject("unidadeStore", "whitelabelStore")(DadosBancariosStyles);
