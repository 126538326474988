import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core'
import Scroll from '../../../../components/InfiniteScroll/Scroll'
import Table from '../../../../components/Table/Table'
import { findAllExtratoPagamento } from '../../../../services/PerfilPublicoService'
import moment from 'moment'

import { Button } from '../../../../components/ui/Buttons'
import PeriodDate from '../../../../components/PeriodDate/PeriodDate'
import { getColumns } from './utils/getColumns'
import PrintIcon from '../../../../components/Icon/Print'
import ImpressaoHtml from '../../../../components/Impressao/ImpressaoHtml'
import ExtratoPagamentosContent from '../../../../template/pdf/perfil-publico/ExtratoPagamentos'
import { inject } from 'mobx-react'

const firstDayInMonth = moment()
  .startOf('month')
  .format('YYYY-MM-DD')
const lastDayInMonth = moment()
  .endOf('month')
  .format('YYYY-MM-DD')

const PAGE_SIZE = 30

const ExtratoPagamentos = ({
  classes,
  isProfissional,
  configuracaoImpressaoStore,
  showAlertMessage,
}) => {
  const [extratoPagamentos, setExtratoPagamentos] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [ordenarTabela, setOrdenarTabela] = useState({
    sortField: 'dataPagamento',
    sortDir: 'DESC',
  })
  const [last, setLast] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [totalElements, setTotalElements] = useState(0)
  const [dadosRelatorio, setDadosRelatorio] = useState([])
  const [isPrintMustache, setIsPrintMustache] = useState(false)
  const [loadingPrinting, setLoadingPrinting] = useState(false)
  const [dateFilter, setDateFilter] = useState({
    dataInicial: firstDayInMonth,
    dataFinal: lastDayInMonth,
  })

  const query = isProfissional
    ? 'findAllForExtratoPagamentoProfissionalSaude'
    : 'findAllForExtratoPagamentoUnidade'

  const findAllExtratoPagamentos = async options => {
    try {
      setIsLoading(true)
      options?.isSearching && setExtratoPagamentos([])
      const response = await findAllExtratoPagamento(query, {
        pageableDTO: {
          pageNumber: options?.isSearching ? 0 : pageNumber,
          pageSize: PAGE_SIZE,
          sortField: ordenarTabela.sortField,
          sortDir: ordenarTabela.sortDir,
        },
        ...(dateFilter.dataInicial && {
          dataInicio: moment(dateFilter.dataInicial).format('YYYY-MM-DDTHH:mm:ss'),
        }),
        ...(dateFilter.dataFinal && {
          dataFim: moment(dateFilter.dataFinal).format('YYYY-MM-DDTHH:mm:ss'),
        }),
      })
      setTotalElements(response?.totalElements)
      setLast(response?.last)
      setPageNumber(prevState => (options?.isSearching ? 0 : prevState + 1))
      if (pageNumber === 0) {
        setExtratoPagamentos(response?.content)
      } else {
        setExtratoPagamentos(prevState => [...prevState, ...response?.content])
      }
    } catch (error) {
      showAlertMessage('Ocorreu um erro ao buscar os extratos', 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeDate = (dataInicial, dataFinal) => {
    setDateFilter({
      dataInicial: dataInicial,
      dataFinal: dataFinal,
    })
  }

  const handleShowCurrentMonth = () => {
    setDateFilter({
      dataInicial: firstDayInMonth,
      dataFinal: lastDayInMonth,
    })
  }

  const handleShowAllMonths = () => {
    setDateFilter({
      dataInicial: null,
      dataFinal: null,
    })
  }

  const handleClickOrdenar = async value => {
    const sortDir =
      ordenarTabela.sortField !== value ? 'ASC' : ordenarTabela.sortDir === 'ASC' ? 'DESC' : 'ASC'

    setOrdenarTabela({
      sortDir: sortDir,
      sortField: value,
    })
  }

  const handlePrint = async () => {
    if (totalElements <= 0) return
    setLoadingPrinting(true)
    try {
      const response = await findAllExtratoPagamento(query, {
        PageableDTO: {
          pageNumber: 0,
          pageSize: totalElements,
          sortField: ordenarTabela.sortField,
          sortDir: ordenarTabela.sortDir,
        },
        ...(dateFilter.dataInicial && {
          dataInicio: moment(dateFilter.dataInicial).format('YYYY-MM-DDTHH:mm:ss'),
        }),
        ...(dateFilter.dataFinal && {
          dataFim: moment(dateFilter.dataFinal).format('YYYY-MM-DDTHH:mm:ss'),
        }),
      })
      await configuracaoImpressaoStore.getConfig('OUTROS')
      setDadosRelatorio(response?.content)
      setIsPrintMustache(true)
    } catch (error) {
      showAlertMessage('Ocorreu um erro ao imprimir os extratos', 'error')
    } finally {
      setLoadingPrinting(false)
    }
  }

  useEffect(() => {
    findAllExtratoPagamentos({ isSearching: true })
  }, [dateFilter.dataInicial, dateFilter.dataFinal, ordenarTabela])

  return (
    <>
      <div className={classes.page}>
        <div className={classes.header}>
          <div className={classes.headerTextArea}>
            <span className={classes.headerTitle}>Extrato de pagamentos</span>
            <span className={classes.headerText}>
              Listagem de todos os agendamentos pagos na plataforma
            </span>
          </div>
          <div className={classes.filterDateContainer}>
            <div className={classes.wrapperFilter}>
              <span> Filtrar por data: </span>
              <PeriodDate
                filter={{
                  dataInicial: dateFilter.dataInicial,
                  dataFinal: dateFilter.dataFinal,
                }}
                setFilter={handleChangeDate}
                onChangeDate={handleChangeDate}
                handleAplicar={() => {}}
                hiddenButtonAplicar
                classes={{
                  container: classes.inputContainer,
                }}
              />
            </div>
            <div className={classes.wrapperFilter}>
              <span>Exibir</span>
              <Button kind='secondary' onClick={handleShowCurrentMonth}>
                Mês atual
              </Button>
            </div>
            <Button kind='primary' onClick={handleShowAllMonths}>
              Tudo
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.content}>
        <Scroll
          hasMore={!isLoading && !last}
          pageStart={0}
          initialLoad={false}
          loadMore={() => findAllExtratoPagamentos()}
          classes={{
            root: classes.tableRoot,
          }}
        >
          <Table
            dados={extratoPagamentos || []}
            columns={getColumns()}
            emptyMessage={
              <div className={classes.emptyMessage}>
                <span>Nenhum registro encontrado</span>
              </div>
            }
            isLoading={isLoading}
            comOrdenacao
            ordenarTabela={ordenarTabela}
            handleClickOrdenar={handleClickOrdenar}
          />
        </Scroll>
      </div>
      <Button
        kind='primary'
        shape='circle'
        bgColor='#F9BE73'
        isLoading={loadingPrinting}
        onClick={handlePrint}
        isDisabled={totalElements <= 0 || extratoPagamentos?.length === 0}
        className={classes.buttonPrint}
      >
        <PrintIcon />
      </Button>

      {isPrintMustache && (
        <ImpressaoHtml
          isPrintMustache={isPrintMustache}
          handlePrintMustache={() => setIsPrintMustache(false)}
          htmlStringComponent={
            <ExtratoPagamentosContent
              dadosRelatorio={dadosRelatorio}
              dataInicio={dateFilter.dataInicial}
              dataFim={dateFilter.dataFinal}
            />
          }
        />
      )}
    </>
  )
}

const styles = {
  page: {
    padding: '0px 16px 16px 16px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItem: 'center',
    justifyContent: 'space-between',
  },
  headerTextArea: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerTitle: {
    fontSize: '20px !important',
    fontWeight: '600',
  },
  headerText: {
    color: '#333333',
  },
  content: {
    overflow: 'hidden',
    height: '100%',
    minHeight: '500px',
    maxHeight: '500px',
  },
  emptyMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    '& span': {
      fontSize: '16px',
      fontWeight: '600',
      color: '#333333',
    },
  },
  tableRoot: {
    width: '100%',
    overflow: 'auto',
    height: 'calc(100% - 14px)',
    '&>div>div>table>thead>tr>th': {
      textAlign: 'start',
      padding: '8px 16px',
    },
    '&>div>div>table>tbody>tr>td': {
      padding: '8px 16px',
    },
  },
  filterDateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
    gap: '16px',
  },
  wrapperFilter: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    '& span': {
      fontSize: '12px',
      fontWeight: '400',
      color: '#868686',
    },
    '& button': {
      padding: '8px 16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  inputContainer: {
    display: 'flex',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '40px',
    border: 'none',
    '& > div': {
      '& > p': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  buttonPrint: {
    position: 'fixed',
    bottom: '80px',
    right: '40px',
  },
}

const ExtratoPagamentosWithStyles = withStyles(styles)(ExtratoPagamentos)
export default inject('configuracaoImpressaoStore')(ExtratoPagamentosWithStyles)
