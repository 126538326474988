import React from "react";
import { Button, withStyles } from "@material-ui/core";
import classNames from "classnames";
import styled from "styled-components";

const styles = {
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F2F2F2",
    justifyContent: "center",
    borderRadius: "100px",
    alignItems: "center",
  },
  button: {
    whiteSpace: "nowrap",
    flex: 1,
    height: "28px",
    textAlign: "center",
    borderRadius: "100px",
    padding: "2px 16px",
    textTransform: "none",
    margin: "2px",
  },
  buttonMinimizado: {
    minWidth: "43px",
    padding: "0px",
    height: "32px",
  },
  buttonSelected: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.16)",
  },
  buttonNotSelected: {
    color: "#868686",
  },
  quantidade: {
    minWidth: "15px",
    maxHeight: "15px",
    minHeight: "15px",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "10px",
    display: "flex",
    borderRadius: "100px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    justifyContent: "center",
    marginLeft: "4px",
  },
  quantidadeMinimizada: {
    marginLeft: "-7px",
    marginBottom: "-21px",
  },
};

const MultiToggleButtons = (props) => {
  const {
    classes,
    tabSelected,
    changeTabSelected,
    disabled,
    posicaoDesabilitada,
    quantidades,
    isButtonMinimized,
    colorQuantidade,
  } = props;

  const Quantidade  = styled.div`
    background: ${props => (props.colorQuantidade || "#717FFC")};
  `;

  const RenderButtonTabs = () => {
    const { options, classes } = props;

    if (options.length > 0) {
      const tabs = options.map((option, idx) => {
        return (
          <Button
            key={idx}
            className={classNames(
              classes.button,
              idx === tabSelected ? classes.buttonSelected : classes.buttonNotSelected,
              isButtonMinimized && classes.buttonMinimizado
            )}
            onClick={() => changeTabSelected(idx)}
            style={{ pointerEvents: 'auto' }}
            disabled={disabled && posicaoDesabilitada.some(item => item === idx)}
          >
            {isButtonMinimized ? (
              <img src={option.icon} alt={option.alt} />
            ) : (
              option
            )}
            {quantidades && !!quantidades[idx] && quantidades[idx] > 0 && (
              <Quantidade
                colorQuantidade={colorQuantidade}
                className={classNames(
                  classes.quantidade,
                  isButtonMinimized && classes.quantidadeMinimizada
                )}
              >
                { quantidades[idx] > 99 ? "99+" : quantidades[idx]}
              </Quantidade>
            )}
          </Button>
        );
      });
      return tabs;
    }
  };

  return <div className={classes.buttonsContainer}>{RenderButtonTabs()}</div>;
};

export default withStyles(styles)(MultiToggleButtons);
