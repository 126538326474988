import React, { forwardRef } from 'react';
import styled from 'styled-components';

import moment from 'moment';
import string from '../../../../utils/string';

const EtiquetaContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  justify-content: center;
  align-items: center;
`;

const Field = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-content: center;
`;

const Text = styled.span`
  font-size: 10px;
  line-height: 1.2;
  font-family: Poppins;
  text-align: start;
`;

const Etiqueta = forwardRef(({ dadosAgendamento, etiquetaSize }, ref) => {
  const {
    nome,
    documento,
    dataNascimento,
    convenio,
    procedimento,
  } = dadosAgendamento || {};
  const { contentWidth, contentHeight } = etiquetaSize || {};

  const idade = moment().diff(moment(dataNascimento, 'YYYY-MM-DD'), 'years');
  const dataNascimentoFormated = moment(dataNascimento, 'YYYY-MM-DD').format(
    'DD/MM/YYYY'
  );

  return (
    <EtiquetaContent
      ref={ref}
      style={{ width: `${contentWidth}mm`, height: `${contentHeight}mm` }}
    >
      <Field>
        <Text>{string.truncate(nome, 40)}</Text>
      </Field>
      <Field>
        <Text>{dataNascimentoFormated}</Text>
        <Text>Idade: {idade}</Text>
      </Field>
      <Field style={{ gap: '32px' }}>
        <Text>CPF: {documento}</Text>
        {convenio?.value && <Text>Convenio: {convenio?.label}</Text>}
      </Field>
      <Field>
        <Text>{string.truncate(procedimento, 40)}</Text>
      </Field>
    </EtiquetaContent>
  );
});

export default Etiqueta;
